<template>
  <div>
    <div v-if="loading" class="p-5">
      <SpinnerLogo />
    </div>
    <component :is="reportComponent" v-else-if="reportComponent" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import SpinnerLogo from '@/components/SpinnerLogo';

export default {
  name: 'MachinePDF',
  components: {
    SpinnerLogo
  },
  data() {
    return {
      debugMode: false,
      loading: true
    };
  },
  computed: {
    ...mapGetters({
      report: 'report/report'
    })
  },
  async mounted() {
    if (this.$route.query.debug === '1') {
      this.debugMode = true;
    }

    if (!this.$route.query.reportId) {
      this.$router.push({ name: 'machine-error', query: { errorCode: 500, errorData: { message: 'Missing report ID' } } });
    }

    if (!this.$route.query.batchId) {
      this.$router.push({ name: 'machine-error', query: { errorCode: 500, errorData: { message: 'Missing batch ID' } } });
    }

    await this.getReport({ id: this.$route.query.reportId });

    if (this.report.batchId !== this.$route.query.batchId) {
      this.$router.push({ name: 'machine-error', query: { errorCode: 500, errorData: { message: 'Batch ID mis-match' } } });
    }

    if (this.report.type !== 'pdf') {
      this.$router.push({ name: 'machine-error', query: { errorCode: 500, errorData: { message: 'Report is not a PDF' } } });
    }

    const componentName = this.idToComponent(this.report.subType);

    if (!componentName) {
      this.$router.push({
        name: 'machine-error',
        query: { errorCode: 500, errorData: { message: `Unsupported report sub-type: ${this.report.subType}` } }
      });
    }

    await this.getCompany({ id: this.report.companyId });

    this.reportComponent = () => import(`./templates/${componentName}PDF`);

    this.loading = false;
  },
  methods: {
    ...mapActions({
      getReport: 'report/get',
      getCompany: 'company/get'
    }),
    idToComponent(str) {
      const camelCase = str.replace(/-(\w)/g, function (match, p1) {
        return p1.toUpperCase();
      });

      return `${camelCase.charAt(0).toUpperCase()}${camelCase.slice(1)}`;
    }
  }
};
</script>
